import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import LegalDoc from '../../modules/legal/components/legal-doc';
import EmptyCell from '../../modules/legal/components/empty-cell';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = function Privacy({
  children
}) {
  return <LegalDoc title="Privacy" updated="18 Oct 2022">{children}</LegalDoc>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ol>
      <li parentName="ol"><strong parentName="li">{`OUR APPROACH TO PRIVACY`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Enigmatic Smile (`}<strong parentName="li">{`"Fidel API"`}</strong>{`, `}<strong parentName="li">{`"we"`}</strong>{`, `}<strong parentName="li">{`"our"`}</strong>{`, or `}<strong parentName="li">{`"us"`}</strong>{`) is committed to protecting your privacy. This Privacy Policy sets out how we collect, store, process, transfer, share and use data that identifies or is associated with you (`}<strong parentName="li">{`"personal information"`}</strong>{`) and information regarding our use of cookies and similar technologies in relation to the Service.`}</li>
          <li parentName="ol">{`Fidel API:`}
            <ol parentName="li">
              <li parentName="ol">{`operates an application programming interface which enables third party operators of loyalty programs (`}<strong parentName="li">{`“Clients”`}</strong>{`) to integrate their loyalty programs with card networks and operators of point of sale equipment (`}<strong parentName="li">{`“Merchants”`}</strong>{`) (`}<strong parentName="li">{`“Select Transactions API”`}</strong>{`);`}</li>
              <li parentName="ol">{`operates an application programming interface which enables third party providers of personal finance and expense management services to integrate their services with card networks (`}<strong parentName="li">{`“Transaction Stream API”`}</strong>{`); `}</li>
              <li parentName="ol">{`provides a web app to provide an experiential and live demonstration of the Transaction Stream API Services from a cardholder perspective via a web app (`}<strong parentName="li">{`“Demo App Services”`}</strong>{`)`}</li>
              <li parentName="ol">{`operates a website (available at `}<a parentName="li" {...{
                  "href": "https://fidelapi.com"
                }}>{`https://fidelapi.com`}</a>{` blog `}<strong parentName="li">{`“Site”`}</strong>{`). `}<br />{`(together, the `}<strong parentName="li">{`“Service”`}</strong>{`).`}</li>
            </ol>
          </li>
          <li parentName="ol">{`Please ensure that you have read and understood how we collect, store, use and disclose your personal information as described in this Privacy Policy. `}</li>
          <li parentName="ol">{`Fidel API is the controller responsible for the personal information we hold about you (except as otherwise stated below). If you have any questions about this Privacy Policy or how we use your personal information, please contact us using the details set out in section 9. below. `}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`PERSONAL INFORMATION (INCLUDING TRANSACTION DATA) WE COLLECT ABOUT YOU AND HOW WE USE IT`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Enigmatic Smile ("Fidel", "we", "our", or "us") is committed to protecting your privacy. This Privacy Policy sets out how we collect, store, process, transfer, share and use data that identifies or is associated with you ("personal information") and information regarding our use of cookies and similar technologies. `}</li>
          <li parentName="ol">{`If you choose not to provide personal information, we may not be able to provide you with the Service or respond to your other requests.`}</li>
          <li parentName="ol">{`The table below sets out the categories of personal information we collect about you, how we use that information and the corresponding legal basis which we rely on to process the personal information.`}
            <div className="legal-table-wrapper">
  <table className="legal-table">
    <thead>
      <tr>
        <th>Category of data subject</th>
        <th>Category of information</th>
        <th>How we use it</th>
        <th>Legal basis for processing</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style={{
                      borderBottom: 'none'
                    }}>
        Clients, Merchants, Demo App users
        </td>
        <td style={{
                      borderBottom: 'none'
                    }}>
          <strong>Contact information and basic personal and professional details.</strong> Such as your name, phone number, address, e-mail address. 
        </td>
        <td>
          We use this information to operate, maintain and provide to you the features and functionality of the Service.
        </td>
        <td>
          The processing is necessary for the performance of a contract and to take steps prior to entering into a contract (such as the Client Terms of Service and Order Form and the Demo App Terms of Service).
        </td>
      </tr>
      <tr>
        <EmptyCell mdxType="EmptyCell" />
        <EmptyCell mdxType="EmptyCell" />
        <td>
          We use this information to communicate directly with you to respond to your queries (for example, support requests) and to send service-related emails or notifications (for example, notifications by SMS or email for account verification or technical and security notices or for transaction alerts in the Demo App Service).
        </td>
        <td>
          This processing is necessary for our legitimate interests, namely, administering the Service and communicating with you in connection with the Service.
        </td>
      </tr>
      <tr>
        <td style={{
                      borderBottom: 'none'
                    }}>Clients, Merchants, Demo App Users, visitors to the Site</td>
        <EmptyCell mdxType="EmptyCell" />
        <td>
          We use this information to send you news, alerts and marketing communications in accordance with your preferences.
        </td>
        <td>
          We will only process your personal information in this way to the extent you have given us consent to do so.
        </td>
      </tr>
      <tr>
        <EmptyCell mdxType="EmptyCell" />
        <EmptyCell mdxType="EmptyCell" />
        <td>
          We use this information to monitor and improve the Service and our business and to help us develop new products and services.
        </td>
        <td>
          The processing is necessary for our legitimate interests, namely to administer and improve the Service and our business.
        </td>
      </tr>
      <tr>
        <td style={{
                      borderBottom: 'none'
                    }}>
          Clients, Merchants, Demo App Users, visitors to the Site
        </td>
        <td style={{
                      borderBottom: 'none'
                    }}>
          <strong>Correspondence, comments and feedback.</strong> When you contact us directly, e.g. by email, when you respond to user surveys or when you provide feedback on the Site, we will record your comments and opinions.
        </td>
        <td>
          We use this information to monitor and improve the Service, and our business and to help us develop new products and services.
        </td>
        <td>
          The processing is necessary for our legitimate interests, namely to administer and improve the Service (including our Site) and our business.
        </td>
      </tr>
      <tr>
        <EmptyCell mdxType="EmptyCell" />
        <EmptyCell mdxType="EmptyCell" />
        <td>
          To address your questions, issues and concerns and to resolve your issues with the Service. 
        </td>
        <td>
          This processing is necessary for our legitimate interests, namely, administering the Service and communicating with you in connection with those questions, issues and concerns.
        </td>
      </tr>
      <tr>
        <td>Clients, Merchants, visitors to Site</td>
        <td><strong>Location information.</strong> Other than information you choose to provide to us, we do not collect information about your precise location. Your device’s IP address may help us determine an approximate location.</td>
        <td>We may use an approximate location to ensure content on our Service is relevant to the city or country you are using your device in.</td>
        <td>The processing is necessary for our legitimate interests, namely to tailor our Service to the user and to improve our Service generally.</td>
      </tr>
      <tr>
        <td>Clients, Merchants, Demo App Users, visitors to the Site </td>
        <td><strong>Preferences.</strong> Preferences set for notifications, marketing communications and how our Service is displayed.</td>
        <td>We use this information to provide notifications related to the functionality of the app (such as notifications by SMS for transaction alerts in the Demo App Service), send marketing communications and display our Service in accordance with your choices.</td>
        <td>The processing is necessary for our legitimate interest, namely ensuring the user receives the correct marketing communications.</td>
      </tr>
    </tbody>
  </table>
            </div>
          </li>
          <li parentName="ol">{`The table below sets out the categories of personal information that include transaction information that we collect about you, how we use that information and the corresponding legal basis which we rely on to process the personal information.`}
            <div className="legal-table-wrapper">
  <table className="legal-table">
    <thead>
      <tr>
        <th>Category of data subject</th>
        <th>Category of information</th>
        <th>How we use it</th>
        <th>Legal basis for processing</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style={{
                      borderBottom: 'none'
                    }}>Clients</td>
        <td style={{
                      borderBottom: 'none'
                    }}><strong>Payment and transaction information.</strong> Information such as payment information, including your credit card or bank account details. </td>
        <td>We use this information to facilitate transactions. For example, transaction information is used to support offers made by Client to its end-users.</td>
        <td>The processing is necessary for the performance of a contract and to take steps prior to entering into a contract (namely the Client Terms of Service and Order Form).</td>
      </tr>
      <tr>
        <EmptyCell mdxType="EmptyCell" />
        <EmptyCell mdxType="EmptyCell" />
        <td>We use this information to detect and prevent fraud. </td>
        <td>The processing is necessary for our legitimate interests, namely the detection and prevention of fraud.</td>
      </tr>
      <tr>
        <td>Demo App Users</td>
        <td><strong>Payment card information.</strong> A subset of your payment card information (the PAN, expiry date) when you link your payment card via your Demo App account.</td>
        <td>We use this information to operate, maintain and provide the features and functionality of the Demo App Service.</td>
        <td>The processing is necessary for the performance of a contract and to take steps prior to entering into a contract (namely the Demo App Terms of Service).</td>
      </tr>
      <tr>
        <EmptyCell last mdxType="EmptyCell" />
        <td><strong>Transaction information.</strong> Information relating to transactions made using your payment card which has been linked to your Demo App account.</td>
        <td>We use this information to operate, maintain and provide the features and functionality of the Demo App Service. For example, we monitor transaction data from your linked card to present the transaction information required for your use of the Demo App Service.</td>
        <td>The processing is necessary for the performance of a contract and to take steps prior to entering into a contract (namely the Demo App Terms of Service).</td>
      </tr>
    </tbody>
  </table>
            </div> 
          </li>
          <li parentName="ol">{`We also automatically collect personal information about you indirectly about how you access and use the Service.`}</li>
          <li parentName="ol">{`The table below sets out the categories of personal information we collect about you automatically across the Services and how we use that information. The table also lists the legal basis which we rely on to process the personal information. `}
            <div className="legal-table-wrapper">
  <table className="legal-table">
    <thead>
      <tr>
        <th>Category of data subject</th>
        <th>Category of information</th>
        <th>How we use it</th>
        <th>Legal basis for processing</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style={{
                      borderBottom: 'none'
                    }}>Clients, Merchants, visitors to the Site, Demo App Users.</td>
        <td style={{
                      borderBottom: 'none'
                    }}><strong>Information about how you access and use the Services, e.g. analytics information.</strong> For example, the website from which you came and the website to which you are going when you leave our Site, how frequently you access the Services, the time you access our Service and how long you use it for, whether you open emails or click the links contained in emails, whether you access the Service from multiple devices, aggregated non-transaction information regarding use of the Demo App account, and other actions you take on the Service. 
        <br /><br />For the avoidance of doubt, this category does not include any transaction information.</td>
        <td>We use this information to determine products and services that may be of interest to you for marketing purposes, including supporting customer inquiries relating to their use of the Demo App, and to analyse the efficacy of our sales strategies, including in relation to the Demo App.</td>
        <td>The processing is necessary for our legitimate interests, namely for sales and marketing purposes. For the avoidance of doubt, no transaction data is used for sales or marketing purposes.</td>
      </tr>
      <tr>
        <EmptyCell mdxType="EmptyCell" />
        <EmptyCell mdxType="EmptyCell" />
        <td>We use this information to monitor and improve our Service and business, and to help us develop new products and services.</td>
        <td>The processing is necessary for our legitimate interests, namely monitoring and improve the Service and help us to develop new products and services.</td>
      </tr>
      <tr>
        <td style={{
                      borderBottom: 'none'
                    }}>Clients, Merchants, visitors to the Site, Demo App Users</td>
        <td style={{
                      borderBottom: 'none'
                    }}><strong>Technical information about your device and its software,</strong> such as your IP address, browser type, Internet service provider, operating system, date and time stamp, and other such information.</td>
        <td>We use this information to present the Service (including the Site) to you on your device and to enhance and personalise your experience.</td>
        <td>The processing is necessary for our legitimate interests, namely to tailor the Service to our users.</td>
      </tr>
      <tr>
        <EmptyCell last mdxType="EmptyCell" />
        <EmptyCell last mdxType="EmptyCell" />
        <td>We use this information to monitor and improve the Service (including the Site).</td>
        <td>The processing is necessary for our legitimate interests, namely to improve the Service (including the Site) and our business generally, to monitor and resolve issues and for other internal purposes.</td>
      </tr>
    </tbody>
  </table>
            </div>
          </li>
          <li parentName="ol">{`In relation to Select Transactions API and Transaction Stream API, the table below sets out the information we collect about Clients’ customers (“End Users”). Please note that we are not the controller of information collected about End Users. The Client (whose loyalty program the End User has registered with) is the controller of this personal information. `}
            <div className="legal-table-wrapper">
  <table className="legal-table">
    <thead>
      <tr>
        <th>Category of data subject</th>
        <th>Category of information</th>
        <th>How we use it</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style={{
                      borderBottom: 'none'
                    }}>End Users</td>
        <td><strong>Payment card information.</strong> A subset of your payment card information (the PAN) when you link your payment card to a loyalty program operated by a Client.</td>
        <td>We use this information to operate, maintain and provide to Clients the features and functionality of the Service.</td>
      </tr>
      <tr>
        <EmptyCell last mdxType="EmptyCell" />
        <td><strong>Transaction information.</strong> A subset of information relating to transactions made using your payment card which has been linked to a Client’s loyalty program, when used for a qualifying transaction at a Merchant. </td>
        <td>We use this information to operate, maintain and provide to Clients the features and functionality of the Service.</td>
      </tr>
    </tbody>
  </table>
            </div>
          </li>
          <li parentName="ol">{`We may link or combine the personal information we collect about you and the information we collect automatically. This allows us to provide you with a personalized experience regardless of how you interact with us.  `}</li>
          <li parentName="ol">{`We may anonymize and aggregate any of the personal information we collect (so that it does not identify you). We may use anonymized information for purposes that include testing our IT systems, research, data analysis, improving the Service (including the Site) and developing new products and features. We may also share such anonymized information with others.  `}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`DISCLOSURE OF YOUR PERSONAL INFORMATION`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`As required in accordance with how we use it, we will share your personal information with the following categories of recipients: `}
            <ol parentName="li">
              <li parentName="ol"><strong parentName="li">{`Service providers and advisors.`}</strong>{` Third party vendors and other service providers that perform services for us, on our behalf, which may include hosting, mailing or email services, data enhancement services, fraud prevention, or providing analytics services. `}</li>
              <li parentName="ol"><strong parentName="li">{`Select third parties.`}</strong>{` Third parties, so that they can provide you with information and marketing messages about products or services that may interest you. `}</li>
              <li parentName="ol"><strong parentName="li">{`Purchasers and third parties in connection with a business transaction.`}</strong>{` Personal information may be disclosed to third parties in connection with a transaction, such as a merger, sale of assets or shares, reorganization, financing, change of control or acquisition of all or a portion of our business.  `}</li>
              <li parentName="ol"><strong parentName="li">{`Law enforcement, regulators and other parties for legal reasons.`}</strong>{` Third parties as required by law or if we reasonably believe that such action is necessary to (a) comply with the law and the reasonable requests of law enforcement; (b) enforce our Terms of Service or to protect the security or integrity of the Service; and/or (c) exercise or protect the rights, property, or personal safety of Fidel API, our customers or others. `}</li>
            </ol>
          </li>
          <li parentName="ol">{`We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`STORING AND TRANSFERRING YOUR PERSONAL INFORMATION`}</strong>
        <ol parentName="li">
          <li parentName="ol"><strong parentName="li">{`Security.`}</strong>{` We implement appropriate technical and organizational measures to protect your personal information against accidental or unlawful destruction, loss, change or damage. All personal information we collect will be stored on secure servers. We take all reasonable steps and follow generally accepted industry standards to ensure that the personal information we hold is protected from misuse, interference, loss, unauthorized access, modification or disclosure by the use of various methods including access limitation, and industry-standard Secure Socket Layer (SSL) encryption technology. We take all reasonable steps to ensure that the personal information we collect, use or disclose is accurate, complete, up-to-date, relevant and stored securely. Security safeguards include data encryption, firewalls, and physical access controls to building and files. Fidel API’s systems are certified as Level 1 PCI compliant and all data retention and credit card information is maintained in accordance with the PCI standards as determined by the PCI Security Standards Council.`}</li>
          <li parentName="ol"><strong parentName="li">{`International transfers of your personal Information.`}</strong>{` The personal information we collect may be transferred to and stored in countries outside of the jurisdiction you are in where we and our third-party service providers have operations. If you are located in the European Economic Area (“EEA”), Switzerland or the UK, your personal information may be processed outside of the EU including in the United States; these international transfers of your personal information will be made pursuant to appropriate safeguards, such as standard data protection clauses adopted by the European Commission or the UK, as appropriate. If you wish to enquire further about these safeguards used, please contact us using the details set out in section 9. below. `}</li>
          <li parentName="ol"><strong parentName="li">{`Retention of your information.`}</strong>{` We will only retain your personal information for as long as necessary to fulfil the purposes we collected it for, including for the purposes of our legitimate business interests and satisfying any legal or reporting requirements.`}</li>
          <li parentName="ol">{`To determine the appropriate retention period for personal information, we consider the amount, nature, and sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure of your personal information, the purposes for which we process your personal information and the applicable legal requirements.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`YOUR RIGHTS IN RESPECT OF YOUR PERSONAL INFORMATION`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`In accordance with applicable privacy law, you have the following rights in respect of your personal information that we hold: `}
            <ol parentName="li">
              <li parentName="ol"><strong parentName="li">{`Right of access and portability.`}</strong>{` The right to obtain access to your personal information along with certain related information.  `}</li>
              <li parentName="ol"><strong parentName="li">{`Right to portability.`}</strong>{` The right to request the transfer of your personal information to you or to a third party. We will provide your personal data in a structured, commonly used, machine-readable format. This right applies only to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you. `}</li>
              <li parentName="ol"><strong parentName="li">{`Right to rectification.`}</strong>{` The right to obtain rectification of your personal information without undue delay where that personal information is inaccurate or incomplete.`}</li>
              <li parentName="ol"><strong parentName="li">{`Right to erasure.`}</strong>{` The right to obtain the erasure of your personal information without undue delay in certain circumstances, such as where the personal information is no longer necessary in relation to the purposes for which it was collected or processed. `}</li>
              <li parentName="ol"><strong parentName="li">{`Right to restriction.`}</strong>{` The right to obtain the restriction of the processing undertaken by us on your personal information in certain circumstances, such as where the accuracy of the personal information is contested by you, for a period enabling us to verify the accuracy of that personal information.`}</li>
              <li parentName="ol"><strong parentName="li">{`Right to object.`}</strong>{` The right to object, on grounds relating to your particular situation, to the processing of your personal information, and to object to processing of your personal information for direct marketing purposes, to the extent it is related to such direct marketing. `}</li>
            </ol>
          </li>
          <li parentName="ol">{`If you wish to exercise one of these rights, please contact us using the details set out in section 9. below. `}</li>
          <li parentName="ol">{`You also have the right to lodge a complaint to your local data protection authority. If you are based in the EU, information about how to contact your local data protection authority is available at: `}<a parentName="li" {...{
              "href": "http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
            }}>{`http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm`}</a>{`. If you are in the UK, information about how to contact the Information Commissioner’s Office is at `}<a parentName="li" {...{
              "href": "https://www.ico.org.uk"
            }}>{`https://www.ico.org.uk`}</a>{`.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`LINKS TO THIRD PARTY SITES`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`The Service may, from time to time, contain links to and from third party websites. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for their policies. Please check the individual policies before you submit any information to those websites. `}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`OUR POLICY TOWARDS CHILDREN`}</strong>{` `}
        <ol parentName="li">
          <li parentName="ol">{`The Service is not directed at persons under 16 and we do not knowingly collect personal information from children. If you become aware that your child has provided us with personal information, without your consent, then please contact us using the details below so that we can take steps to remove such information and terminate any account your child has created with us.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`CHANGES TO THIS POLICY`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`We may update this Privacy Policy from time to time and so you should review this page periodically. When we change this Privacy Policy in a material way, we will update the “last modified” date at the end of this Privacy Policy. Changes to this Privacy Policy are effective when they are posted on this page. `}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`CONTACTING US`}</strong>{` `}
        <ol parentName="li">
          <li parentName="ol">{`You can contact us with any query related to this Privacy Policy by emailing us at `}<a parentName="li" {...{
              "href": "mailto:support@fidelapi.com"
            }}>{`support@fidelapi.com`}</a>{`.`}</li>
        </ol>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      